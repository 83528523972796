//@import "variables";
// @import "../bootstrap/scss/bootstrap";
@import './functions';
@import "../../../node_modules//bootstrap/scss/bootstrap";

@import "variables";
@import "navbar";
@import "../fontawesome/scss/fontawesome.scss";
@import "../fontawesome/scss/solid.scss";
@import "../fontawesome/scss/brands.scss";

/**
 * Generic elements
 */

body {
  // margin: 0;
  font-size: $font-size-base-mem; //20px
  font-family: $font-family-base;
  color: $black-mem;
  font-weight: 300;
}

.main-wrapper {
  margin: 0 auto;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  position: relative;
  height: 100%;
}
.container-xxl {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
.container-mem {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

p {
  font-family: $font-inter-light;
  font-size: $font-size-medium;
  line-height: 1.4;
}
a {
  font-family: $font-inter-light;
  color: $black-mem;
  text-decoration: underline;
  &:hover {
    color: $red-light;
  }
}
.small {
  font-family: $font-inter-bold;
  font-size: $font-size-small;
  letter-spacing: 0.1rem;
  word-spacing: 0.15px;
  margin-bottom: 2em;
}
.text-bold {
  font-family: $font-inter-medium;
}

.french-warning {
  font-family: $font-oswald-light;
  font-size: $font-size-base-mem;
}

h1 {
  font-family: $font-inter-light;
  font-size: $font-size-xlarge; // 60px
  @include media-breakpoint-down(lg) {
    font-size: 3rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.9rem;
  }
  @media (max-width: 375px) {
    font-size: 1.8rem;
  }
}
h2 {
  padding: 1.9rem 0;
  font-family: $font-oswald-light;
  font-size: $font-size-large;
  word-spacing: 0.2rem;
  line-height: 1.4;
}

h3 {
  font-family: $font-inter-medium;
  padding: 0.5em 0;
}
.h3 {
  font-size: $h3-font-size;
}
h4 {
  font-family: $font-inter-medium;
}

h5 {
  font-family: $font-inter-medium;
  font-size: $font-size-h5;
}

.font-oswald {
  font-family: $font-oswald-light;
}

ul {
  font-family: $font-inter-light;
  padding-left: 1em;
}

.text-vedette {
  font-family: $font-inter-light;
  font-size: $h1-font-size; //40px
  line-height: 1.2;
  @include media-breakpoint-down(sm) {
    font-size: $h3-font-size; //28px
  }
}

.grand-titre {
  font-family: $font-oswald-light;
  font-size: $font-size-xxlarge;
  line-height: 1.2;
}

.fleche {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.25em;
  mask-image: url("../img/ui/cta-fleche-droite.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: $black-mem;
  display: inline-block;
  vertical-align: middle;
  &.fleche-lft {
    mask-image: url("../img/ui/cta-fleche-gauche.svg");
    margin-left: 0;
    margin-right: 0.25em;
  }
}

.fleche-left {
  width: 100%;
  height: 50%;
  margin-left: 0.5em;
  background-image: url("../img/ui/carrousel-fleche-gauche.svg");
  background-repeat: no-repeat;
}
.fleche-right {
  width: 100%;
  height: 50%;
  margin-right: 0.5em;
  background-image: url("../img/ui/carrousel-fleche-droite.svg");
  background-repeat: no-repeat;
}

.linkfleche {
  margin-top: 2em;
  a {
    color: $black-mem;
    border-bottom: 1px solid $black-mem;
    font-family: $font-inter-light;
    font-size: $font-size-medium; //20px;
    text-decoration: none;
    @media not all and (pointer: coarse) {
      &:hover {
        text-decoration: none;
        background-color: $red-light;
        border-bottom-color: $red-light;
        color: #fff;

        .fleche {
          background-color: #fff;
        }
      }
    }
    .fleche {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &.bgredlight {
    a {
      color: white;
      border-bottom: 1px solid white;
      transition: all 1s ease;
      &:hover {
        background-color: white;
        color: $red-light;
        border-bottom-color: $red-light;
        .fleche {
          background-color: $red-light;
        }
      }
      .fleche {
        width: 1.5rem;
        height: 1.5rem;
        background-color: white;
      }
    }
  }
  &.darkbgred {
    a {
      color: white;
      border-bottom: 1px solid white;
      transition: all 1s ease;
      &:hover {
        background-color: white;
        color: $red-dark;
        border-bottom-color: $red-dark;
        .fleche {
          background-color: $red-dark;
        }
      }
      .fleche {
        width: 1.5rem;
        height: 1.5rem;
        background-color: white;
      }
    }
  }
  &.big-text {
    a {
      font-size: $h1-font-size;
      opacity: 0.5;
      padding: 0 0.3em 0 0.4em;
      transition: all 1s ease;
      text-decoration: none;
      &:hover {
        opacity: 1;
        background-color: $red-dark;
        color: white;
        border-bottom-color: white;
        .fleche {
          background-color: white;
        }
      }
      .fleche {
        width: 3rem;
        height: 3rem;
        margin-left: 2em;
        @media (max-width: 400px) {
          margin-left: 0.8em;
          width: 2.3rem;
          height: 2.3rem;
        }
      }
      @media (max-width: 500px) {
        font-size: 1.8rem;
      }
    }
  }
  &.graybg {
    display: inline-flex;
    flex-direction: column;
    border-bottom: 1px solid $black-mem;
    transition: all 1s ease;
    a {
      border-bottom: none;
      opacity: 1;
      color: $gray-darker;
      .fleche {
        background-color: $gray-darker;
      }
      &:hover {
        background-color: transparent;
        color: $black-mem;
        .fleche {
          background-color: $black-mem;
        }
      }
    }
  }
  &.bganimate {
    display: inline-block;
    a {
      font-size: $font-size-medium;
      display: block;
      // padding: 4px;
      transition: all 0.5s;
      position: relative;
      @media not all and (pointer: coarse) {
        &:after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: $red-light;
          transition: all 0.5s;
        }
      }
      span {
        position: relative;
        z-index: 2;
      }

      @media (max-width: 320px) {
        font-size: 1.15rem;
      }
      @media not all and (pointer: coarse) {
        &:hover {
          color: white;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.btn-lg {
  border: 1px solid $black-mem;
  font-family: $font-inter-light;
  font-size: $h1-font-size;
  margin-top: 1.5em;
  text-decoration: none;
  &:hover {
    background-color: $red-dark;
    color: white;
    border: 1px solid $red-dark;
  }
  @include media-breakpoint-down(sm) {
    font-size: $h3-font-size; //28
  }
  @media (max-width: 440px) {
    font-size: 1.62rem; //26px
  }
  @media (max-width: 375px) {
    font-size: 1.4rem; //22.4px
  }
}
.red-text {
  color: $red-dark;
}

.red-light-text {
  color: $red-light;
}
.special-block {
  color: $red-light;
  border: 2px solid $red-light;
  padding: 1rem;
}

br.split-xs {
    display: none;
    @media (max-width: 425px) {
      display: block;
    }
 
}

.infrench {
  color: $gray-darker;
}

.card-mem {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  .image {
    width: 100%;
    min-height: 260px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &.expo {
      background-image: url("../img/pages/montreal.jpg");
    }
    &.activcult {
      background-image: url("../img/pages/activ-deco.jpg");
    }
    &.activeduc {
      background-image: url("../img/pages/dossier-education.jpg");
    }
    &.boutique {
      background-image: url("../img/pages/boutique2.jpg");
    }
  }
  .card-container {
    max-width: 800px;
    @media screen and (min-width: 1664px) {
      // padding: 12% 2.5em 12% 0;
      padding: 12% 2.5em 12% 1em;
    }
    @media screen and (max-width: 1664px) {
      padding: 12% 2.5em 12% 2.5em;
    }
    @media screen and (max-width: 1200px) {
      padding: 12% 1.5em 12% 1.5em;
    }
    @media screen and (min-width: 2000px) {
      margin: 0 auto;
    }
    .top-card {
      .small {
        margin-bottom: 2em;
      }
      .grand-titre {
        margin-bottom: 1.5em;
        padding: 0;
        line-height: 1.1;
        font-size: calc(30px + 1.57vw);
        // calc(40px + 1.57vw);
      }
    }
    .text-card {
      p,
      a {
        font-size: $font-size-xmedium;
        @media (max-width: 768px) {
          font-size: $font-size-base-mem; //20px
        }
      }
      .linkfleche {
        a {
          .fleche {
            width: 2rem;
            height: 2rem;
            @media (max-width: 375px) {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
    }
  }
}

.home-page {
  .jumbotron {
    // background-image: url("../img/header/ronde.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $gray-lighter;
    background-position: 50%;
    width: 100%;
    &.img-ronde {
      background-position: 70% 0;
    }
    &.img-coureur {
      @media (min-width: 992px) and (max-width: 1450px) {
        background-position: 20%;
      }
    }
    &.img-hiver,
    &.img-plongeur,
    &.img-mobile-hiver,
    &.img-mobile-plongeur {
      @media (max-width: 992px) {
        background-position: top center;
      }
    }

    .container {
      margin-top: 5em;
      @media (max-width: 992px) and (orientation: landscape) {
        .logo-mem {
          display: flex;
          justify-content: center;
          img {
            width: 65%;
          }
        }
      }
    }
  }
  section {
    &.top-section {
      // background-color:$red-dark;
      padding: 7em 0;
      @include media-breakpoint-down(md) {
        padding: 4em 1.5em;
      }
      // .text-vedette {
      // 	a {
      // 		color: $white;
      // 	}
      // }
      h1 {
        margin-top: 2.2em;
        margin-bottom: 1em;
      }
      .linkfleche {
        margin-top: 4em;
        @include media-breakpoint-down(md) {
          margin-top: 2.5em;
        }
        &.bganimate {
          a {
            opacity: 1;
            &:hover {
              color: $white;
            }
          }
        }
        &.big-text {
          a {
            font-size: 2.5rem;
            @media (max-width: 578px) {
              font-size: 1.9rem;
              .fleche {
                width: 2.5rem;
                height: 2.5rem;
                margin-left: 0.5em;
              }
            }
            @media (max-width: 420px) {
              font-size: 1.8rem;
              .fleche {
                width: 2rem;
                height: 2rem;
                margin-left: 0.3em;
              }
            }
            @media (max-width: 375px) {
              font-size: 1.7rem;
              padding: 0;
              .fleche {
                width: 2rem;
                height: 2rem;
                margin-left: 0.3em;
              }
            }
          }
        }
      }
    }
    // &.gray-section {
    //   background-color: $gray-lighter;
    // }
    &.concours-section {
      // padding-top: 7em;
      padding-bottom: 8.75rem; // 140px
      @include media-breakpoint-down(sm) {
        padding-top: 4em;
      }
      @media (max-width: 567px) {
        padding-top: 3em;
      }
      .content-concours {
        padding: 0 2.5em;
        @include media-breakpoint-down(md) {
          padding: 0 1.5em;
        }
        h1 {
          margin-bottom: 9%;
        }
        p {
          font-size: $font-size-xmedium;
          // font-size: 28px;
        }
      }
      .container-carousel {
        margin: 5em 0 3em 0;
        @include media-breakpoint-down(sm) {
          margin: 2em 0;
        }
      }
    }
    &.expositions {
      // padding-top: 7em;
      // @include media-breakpoint-down(md) {
      // 	padding-top: 4em;
      // }
      // @include media-breakpoint-down(sm) {
      // 	padding-top: 3em;
      // }
      // .card-mem {
      // 	padding-top: 6em;
      // 	@include media-breakpoint-down(md) {
      // 		padding-top: 4em;
      // 	}
      // 	@include media-breakpoint-down(sm) {
      // 		padding-top: 3em;
      // 	}
      // }
      .bg-red-light {
        background-color: $red-light;
      }
      .bg-red-dark {
        background-color: $red-dark;
      }
    }
    &.memoire {
      background-color: $gray-lighter;
      font-family: $font-oswald-light;
      padding: 8em 0 9em 0;
      @include media-breakpoint-down(md) {
        padding: 5em 0 6em 0;
      }
      @include media-breakpoint-down(sm) {
        padding: 3em 0 4em 0;
      }
      .bganimate {
        a {
          .fleche {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      .content-memoire {
        padding: 0 2.5em 4em 2.5em;
        @media (min-width: 1664px) {
          padding-left: 0;
        }
        
        @media (max-width: 1200px) {
          padding: 0 1.5em 2em 1.5em;
        }
        .grand-titre {
          margin-bottom: 3em;
          @include media-breakpoint-down(md) {
            margin-bottom: 2em;
          }
        }
        p,
        a {
          font-size: $font-size-xmedium;
          // font-size: 28px;
        }
      }
    }
    &.activites-educ {
      .content-activites {
        padding: 7em 2.5em;
        @include media-breakpoint-down(md) {
          padding: 5em 1.5em;
        }
        @include media-breakpoint-down(sm) {
          padding: 4em 1.5em;
          .btn-lg {
            font-size: 1.7rem;
          }
        }
        @media (max-width: 420px) {
          padding: 4em 1.5rem;
        }
        .content-activ-educ {
          padding-top: 4em;
          p {
            font-size: $font-size-xmedium;
            // font-size: 28px;
          }
        }
        .btn-lg {
          margin-top: 2.5em;
          @media (max-width: 500px) {
            font-size: 1.5rem;
          }
          @media (max-width: 420px) {
            font-size: 1.3rem;
          }
          @media (max-width: 375px) {
            font-size: 1.1rem;
          }
        }

        .content-activ-educ {
          margin-bottom: 5em;
          @include media-breakpoint-down(sm) {
            margin-bottom: 3em;
          }
          h1 {
            margin: 0.5em 0 1.5em 0;
          }
        }
      }
    }
  }
}

// Carousell Concours
#concurs-carousel {
  .carousel-concours {
    transition: 0.5s linear;
    display: table !important;
    table-layout: fixed !important;
    width: 100% !important;
    .slick-list {
      .slick-track {
        display: flex;
        align-items: flex-end;
        .slick-slide {
          margin: 5px;
          outline: none;
          img {
            max-width: 350px;
            height: auto;
            &.verticale1 {
              max-width: 200px;
              height: auto;
            }
            &.verticale2 {
              max-width: 180px;
            }
            &.verticale3 {
              max-width: 200px;
            }
            &.verticale4 {
              max-width: 150px;
            }
          }
          &:hover {
            cursor: pointer;
          }
          .caption {
            display: none;
            font-size: $font-size-small;
            font-family: $font-inter-light;
          }
        }
      }
      &.overflow-visible {
        overflow: visible;
        .item {
          transform: translateX(-25%);
          transition: transform 300ms ease 100ms;
          transition: 0.5s linear;
          //- si on a des images verticale il faut ajouter des class sur chaque image et regler la distance dans translate
          // &.verticale1 {
          // 	transform: translateX(-42%);
          // }
          // &.verticale2 {
          // 	transform: translateX(-48%);
          // }
          // &.verticale3 {
          // 	transform: translateX(-42%);
          // }
          // &.verticale4 {
          // 	transform: translateX(-57%);
          // }
          &:hover,
          &:focus-within {
            transform: scale(1.5);
            z-index: 1;
            @media (max-width: 768px) {
              -webkit-transform: scale(1.2);
              -moz-transform: scale(1.2);
              -o-transform: scale(1.2);
              transform: scale(1.2);
              transform: scale(1.2);
              // &.verticale1,
              // &.verticale2,
              // &.verticale3,
              // &.verticale4 {
              // 	transform: scale(1.8);
              // }
            }
            @media (max-width: 375px) {
              -webkit-transform: scale(1.08);
              -moz-transform: scale(1.08);
              -o-transform: scale(1.08);
              transform: scale(1.08);
              transform: scale(1.08);
            }
            ~ .item {
              transform: translateX(25%);
              // transition: .5s linear;
              //- si on a des images verticale il faut ajouter des class sur chaque image et regler la distance dans translate
              //transition: transform 500ms!important;
              // &.verticale1 {
              // 	transform: translateX(42%);
              // }
              // &.verticale2 {
              // 	transform: translateX(48%);
              // }
              // &.verticale3 {
              // 	transform: translateX(42%);
              // }
              // &.verticale4 {
              // 	transform: translateX(57%);
              // }
            }
            .caption {
              display: block;
            }
          }
        }
      }
    }
    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-25%);
      z-index: 5;
      width: 100px;
      top: 50%;
      opacity: 0.5;
      &.fleche-right {
        right: 0;
        padding-right: 1rem;
        z-index: 5;
        &:hover {
          opacity: 1;
        }
        @media (max-width: 464px) {
          width: 70px;
        }
      }
      &.fleche-left {
        left: 0;
        padding-left: 1rem;
        z-index: 5;
        &:hover {
          opacity: 1;
        }
        @media (max-width: 464px) {
          width: 70px;
        }
      }
      &.fleche-overlay-left {
        position: absolute;
        width: 100px;
        height: 280px;
        top: 25%;
        left: 0;
        z-index: 1;
        @media (min-width: 992px) and (max-width: 1200px) {
          width: 70px;
        }
        @media (min-width: 768px) and (max-width: 992px) {
          width: 70px;
        }
        @media (min-width: 564px) and (max-width: 768px) {
          width: 100px;
        }
        @media (min-width: 464px) and (max-width: 564px) {
          width: 60px;
        }
        @media (max-width: 464px) {
          width: 40px;
        }
      }
      &.fleche-overlay-right {
        position: absolute;
        width: 100px;
        height: 280px;
        top: 25%;
        right: 0;
        z-index: 1;
        @media (min-width: 992px) and (max-width: 1200px) {
          width: 70px;
        }
        @media (min-width: 768px) and (max-width: 992px) {
          width: 70px;
        }
        @media (min-width: 564px) and (max-width: 768px) {
          width: 100px;
        }
        @media (min-width: 464px) and (max-width: 564px) {
          width: 60px;
        }
        @media (max-width: 464px) {
          width: 40px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

/* 
* Carousel Memoire 
*/
#memoire-carousel {
  .carousel-inner {
    &.overflow-visible {
      overflow: visible;
    }
    .carousel-item {
      opacity: 0.5;
      margin: 0 2rem;
      @media (min-width: 1200px) and (max-width: 1600px) {
        margin: 0 20px;
      }
      @include media-breakpoint-down(lg) {
        margin: 0 25px;
      }
      @include media-breakpoint-down(md) {
        margin: 0 15px;
      }
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
      .item {
        height: 12em;
        @media (min-width: 1200px) and (max-width: 1600px) {
          height: 11em;
        }
        @include media-breakpoint-down(lg) {
          height: 10em;
        }
        @include media-breakpoint-down(md) {
          height: 8em;
        }
        @include media-breakpoint-down(sm) {
          height: 6em;
        }
        img {
          display: none;
        }
        a {
          cursor: none;
          color: $gray-dark;
          font-size: $font-size-xlarge; //60px
          line-height: 1.2;
          -webkit-box-orient: vertical;
          display: block;
          display: -webkit-box;
          overflow: hidden !important;
          text-overflow: ellipsis;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          text-decoration: none;
          font-family: $font-oswald-light;
          @media (min-width: 1200px) and (max-width: 1600px) {
            font-size: 3.438rem; //55px
          }
          @include media-breakpoint-down(lg) {
            font-size: 3.125rem; //50px
          }
          @include media-breakpoint-down(md) {
            font-size: 2.5rem; //40px
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.875rem; //30px
          }
        }
      }
      //middle item display
      &.active + .carousel-item {
        display: block;
        opacity: 1;
        .item {
          transition: 0.5s linear;
          img {
            transition: transform 500ms !important;
          }
          &:hover {
            img {
              display: inline-block;
              max-width: 56.7%; //446px
              position: absolute;
              transform: translateX(-117%);
              z-index: 3;
              @include media-breakpoint-down(lg) {
                max-width: 45%;
                transform: translateX(-117%);
              }
              @include media-breakpoint-down(md) {
                display: none;
              }
              &.img-verticale {
                width: auto;
                max-height: 56.7%; //446px
                transform: translateX(-123%);
                @media (min-width: 1900px) {
                  transform: translateX(-128%);
                }
                @media (min-width: 1800px) and (max-width: 1900px) {
                  transform: translateX(-125%);
                }
                @media (min-width: 1700px) and (max-width: 1800px) {
                  transform: translateX(-124%);
                }
                @media (min-width: 1600px) and (max-width: 1700px) {
                  transform: translateX(-122%);
                }
                @media (min-width: 1400px) and (max-width: 1500px) {
                  transform: translateX(-122%);
                }
                @media (min-width: 1300px) and (max-width: 1400px) {
                  transform: translateX(-120%);
                }
                @media (min-width: 1200px) and (max-width: 1300px) {
                  transform: translateX(-118.5%);
                }
                @include media-breakpoint-down(lg) {
                  max-height: 45%;
                }
                @media (min-width: 1124px) and (max-width: 1200px) {
                  transform: translateX(-122%);
                }
                @media (min-width: 992px) and (max-width: 1124px) {
                  transform: translateX(-120%);
                }
              }
            }
            a {
              color: $red-light;
              &:hover {
                cursor: pointer;
                text-decoration: none;
              }
            }
            &:last-child {
              img {
                bottom: 0;
              }
            }
          }
        }
        & + .carousel-item {
          display: block;
          a {
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      &.active {
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .carousel-fleche-left,
  .carousel-fleche-right {
    display: inline-block;
    width: 100%;
    margin-bottom: 3.5rem;
  }
  // @media (max-width:576px) {
  // 	.carousel-control-prev {
  // 		.carousel-fleche-left {
  // 			display:none;
  // 		}
  // 		// &.visible-mobile {
  // 		// 	.carousel-fleche-left {
  // 		// 		display: inline-block;
  // 		// 	}
  // 		// }
  // 	}

  // }
}

/* Jumbotrom - pages secondaire */
body:not(.home-page) {
  .jumbotron {
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: $red-dark;
    background-position: bottom right;
    height: 71.58vh;
    margin-bottom: 0;
    // clip-path: polygon(calc(100%-70vw) 0%, 100% 0%, 100% 100%, 67% 100%);
    @media (max-width: 768px) {
      height: 68vh;
    }
    &.a-propos {
      background-image: url("../img/bandeau/apropos.jpg");
      @media (max-width: 768px) {
        background-image: url("../img/bandeau/mobile-apropos.jpg");
        background-position: 40%;
      }
    }
    &.concours {
      background-image: url("../img/bandeau/concours-photo.jpg");
      @media (min-width: 567px) and (max-width: 992px) {
        background-position-x: 80%;
      }
      @media (max-width: 567px) {
        background-image: url("../img/bandeau/mobile-concours-photo.jpg");
      }
    }
    &.balado {
      background-image: url("../img/bandeau/balados.jpg");
      @media (min-width: 768px) and (max-width: 1200px) {
        background-position: 75%;
      }
      @media (min-width: 567px) and (max-width: 768px) {
        background-position: 75%;
      }
      @media (max-width: 567px) {
        background-image: url("../img/bandeau/mobile-balados.jpg");
      }
    }
    &.informations {
      background-image: url("../img/bandeau/infos-pratiques.jpg");
      @media (min-width: 1200px) and (max-width: 1390px) {
        background-position: 75%;
      }
      @media (min-width: 760px) and (max-width: 1440px) {
        background-position: 75%;
      }
      @media (min-width: 567px) and (max-width: 760px) {
        background-position: 69%;
      }
      @media (max-width: 567px) {
        background-position: 69%;
      }
    }
    &.programmation {
      background-image: url("../img/bandeau/programmation.jpg");
      background-position: 75%;
    }
    &.activites {
      background-image: url("../img/bandeau/educatif.jpg");
      @media (max-width: 567px) {
        background-image: url("../img/bandeau/mobile-educatif.jpg");
      }
    }
    &.programmation-horsmurs {
      background-image: url("../img/bandeau/horslesmurs.jpg");
    }
    &.programmation-expo {
      background-image: url("../img/bandeau/montreal.jpg");
    }
    &.activeduc-primaire {
      background-image: url("../img/bandeau/primaire.png");
    }
    &.activeduc-secondaire {
      background-image: url("../img/bandeau/secondaire.jpg");
    }
    &.activeduc-francisation {
      background-image: url("../img/bandeau/francisation.jpg");
    }
     &.activeduc-adulte {
      background-image: url("../img/bandeau/adulte.jpg");
    }
    &.programmation-activcult {
      background-image: url("../img/bandeau/activ-deco-3.jpg");
      background-position: top center;
    }
    .content-overlay {
      //see javascript function trapezRed()
      position: absolute;
      bottom: 0;
      top: 0;
    }
    
    @media screen and (min-width: 1665px) {
      .container-xxl {
        padding: 0;
      }
    }
    .jumbotron-content {
      height: 71.61vh;
      position: absolute;
      top: 0;
      width: 100%;
      padding-bottom: 1.5em;
      @media (max-width: 1665px) {
        padding-left: 2.5em;
      }
      @media (max-width: 1200px) {
        padding-left: 1.5em;
      }
      @media (max-width: 768px) {
        height: 68vh;
        width: 50vw;
        padding-bottom: 1em;
      }
      @media (max-width: 320px) {
        padding-bottom: 0.5em;
      }
      h1 {
        color: $gray-lighter;
        &.split {
           br {
            display: block;
           }
        }
        br {
          display: none;
          @media (max-width: 768px) {
            display: block;
          }
        }
        @media (min-width: 768px) and (max-width: 992px) {
          font-size: 2.7rem;
        }
        @media (min-width: 586px) and (max-width: 768px) {
          font-size: 2.4rem;
          line-height: 1;
        }
        @media (max-width: 375px) {
          font-size: 1.7rem;
          line-height: 1;
        }

        @media (max-width: 360px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}

/* Section comune - pages secondaire */
section {
  &.top {
    background-color: $gray-lighter;
    padding: 4% 2.5em;
    // @media (max-width: 768px) {
    // 	padding: 4% 1.5em;
    // }
    @media (max-width: 1200px) {
      padding: 4% 1.5em;
    }
    .grand-titre {
      color: $red-light;
      font-family: $font-oswald-light;
      word-spacing: 0.2rem;
      font-size: $font-size-large; // 48px
      line-height: 1.4;
      a {
        color: $red-light;
        font-family: $font-oswald-light;
        &.email {
          text-transform: lowercase;
        }
      }
    }
  }
}

/*
* Page - Concours
*/
.concours-page {
  section {
    &.intro {
      padding: 3em 0 1.5em 0;
      .container-text {
        padding: 0 2.5em;
        @media (max-width: 1200px) {
          padding: 0 1.5em;
        }
        .date-concours {
          color: $red-light;
          font-family: $font-inter-medium;
        }
      }
      .nav-concours {
        &.sticky {
          position: fixed;
          position: -webkit-sticky; /* Safari */
          top: 97px;
          background-color: $red-light;
          z-index: 12;
          // top: 0;
          transition: all 0.3s ease;
          .menu-concours {
            padding: 0.8em 0 0 0;
            a {
              background-image: none;
              color: #fff !important;
              -webkit-text-fill-color: initial;
              &:before {
                background: $black-mem;
              }
              &:hover {
                color: $black-mem !important;
                .fleche {
                  background-color: $black-mem !important;
                }
              }
              .fleche {
                background-color: #fff;
              }
              &.active {
                color: $black-mem !important;
                transition: none;
                &:before {
                  width: 100%;
                }
              }
            }
            li {
              margin-bottom: 0;
            }
          }
        }
      }
      .menu-concours {
        padding: 3em 0;
        a {
          color: $black-mem !important;
          font-family: $font-inter-medium;
          text-decoration: none;
          display: inline;
          padding: 4px 0;
          line-height: 1;
          position: relative;
          z-index: 1;
          text-align: center;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
          background-image: linear-gradient(
            to right,
            $red-light,
            $red-light 50%,
            $black-mem 50%
          );
          background-image: -webkit-linear-gradient(
            to right,
            $red-light,
            $red-light 50%,
            $black-mem 50%
          );
          background-image: -moz-linear-gradient(
            to right,
            $red-light,
            $red-light 50%,
            $black-mem 50%
          );
          background-image: -o-linear-gradient(
            to right,
            $red-light,
            $red-light 50%,
            $black-mem 50%
          );
          background-size: 200% 100%;
          background-position: -100%;
          transition: all 0.3s ease-in-out;
          &:before {
            display: block;
            content: "";
            width: 0;
            height: 3px;
            bottom: 5px;
            left: 0;
            bottom: -3px;
            z-index: 0;
            position: absolute;
            background: $red-light;
            transition: all 0.3s ease-in-out;
          }
          @media not all and (pointer: coarse) {
            &:hover {
              color: $red-light !important;
              background-position: 0%;
              &:before {
                width: 100%;
              }
              .fleche {
                background-color: $red-light !important;
              }
            }
          }
        }
        .linkfleche {
          a {
            &:hover {
              .fleche {
                background-color: $red-light !important;
              }
            }
          }
        }
        @media (max-width: 992px) {
          padding: 2em 0 0 0;
          li {
            margin-bottom: 1em;
          }
        }
      }
      .credit-img {
        padding: 0.5em 2.5em;
        p {
          font-size: $font-size-small;
        }
        @media (max-width: 1200px) {
          padding: 0.5em 1.5em;
        }
      }
    }
    &.container-concours {
      .col-right {
        // contain: content;
        .btnConcours {
          .btn-lg {
            font-size: 1.75rem;
            @media (max-width: 1200px) {
              font-size: 1.4rem;
            }
          }
          .btn {
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          &.sticky {
            position: fixed;
            position: -webkit-sticky; /* Safari */
            top: 0;
            transition: all 0.5s ease;
          }
        }
      }
    }
  }

  .container-concours {
    padding: 0 2.5em 5em 2.5em;
    @media (max-width: 1200px) {
      padding: 0 1.5em 3em 1.5em;
    }
    #reglement,
    #prix {
      margin-top: 2em;
    }
    .text-vedete {
      .linkfleche {
        margin-top: 0;
        a {
          font-size: 1.5rem;
          font-family: $font-inter-medium;
        }
      }
    }
  }
}

/* 
* Page - À propos 
*/
.apropos {
  .content-apropos {
    padding: 3em 2.5em 4em 2.5em;
    @media (max-width: 1200px) {
      padding: 2em 1.5em 3em 1.5em;
    }
    .block-video {
      margin-top: 3em;
      @media (max-width: 576px) {
        margin-top: 2em;
      }
      .video-mem {
        height: 95% !important;
      }
    }
    .team-list {
      @media (min-width: 920px) {
        column-count: 2;
      }
      .member {
        margin-bottom: 1.5rem;
        min-height: 128px;
        @media (max-width: 920px) {
          min-height: auto;
        }
        img {
          display: block;
          width: 100px;
          height: 100px;
          margin-right: 0.5rem;
          @media (max-width: 576px) {
            width: 80px;
            height: 80px;
          }
        }
        .info-member {
          .name {
            font-family: $font-inter-medium;
          }
          .post,
          .email {
            font-size: $font-size-medium-var;
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

/* 
* Page - Activités educatives
*/
.activites-educ {
  .card-mem {
    .image {
      aspect-ratio: 3 / 2;
      &.primaire {
        background-image: url("../img/pages/primaire.jpg");
      }
      &.secondaire {
        background-image: url("../img/pages/etincelle-citoyenne.jpg");
      }
      &.francisation {
        background-image: url("../img/pages/francisation.jpg");
      }
      &.adultes {
         background-image: url("../img/pages/adultes.jpg");
      }
    }
    .card-container {
      @media (min-width: 1664px) {
        padding: 9% 2.5em 8.5% 0;
      }
      @media (max-width: 1664px) {
        padding: 9% 2.5em 8.5% 2.5em;
      }
      @media (max-width: 1200px) {
        padding: 9% 1.5em 8.5% 1.5em;
      }
      @media (max-width: 567px) {
        padding: 3em 1.5em;
      }
      .top-card {
        margin-bottom: 1.5em;
        .small {
          @media (max-width: 1200) {
            margin-bottom: 1.5em;
          }
        }
        .french-warning {
          font-size: $font-size-small;
          font-family: $font-inter-medium;
        }
        .detail-event {
          margin-top: 1rem;
          .detail {
            font-size: 0.9rem;
          }
        }
        .credit-img {
          font-size: 0.9rem;
          margin-top: 1rem;
        }
        .grand-titre {
          //4.375rem;
          margin-bottom: 0;
          @media (min-width: 1500px) and (max-width: 1600px) {
            font-size: calc(40px + 1.3vw);
          }
          @media (min-width: 1400px) and (max-width: 1500px) {
            font-size: calc(40px + 1.2vw);
          }
          @media (min-width: 1300px) and (max-width: 1400px) {
            font-size: calc(40px + 1.2vw);
          }
          @media (min-width: 1200px) and (max-width: 1300px) {
            font-size: calc(40px + 1vw);
          }
          @media (min-width: 1100px) and (max-width: 1200px) {
            font-size: 2.3rem;
          }
          @media (min-width: 992px) and (max-width: 1100px) {
            font-size: 2rem;
          }
        }
      }
      .text-card {
        p,
        a {
          //1.75
          @media (min-width: 1500px) and (max-width: 1600px) {
            font-size: calc(16px + 0.5vw);
          }
          @media (min-width: 1400px) and (max-width: 1500px) {
            font-size: calc(16px + 0.4vw);
          }
          @media (min-width: 1300px) and (max-width: 1400px) {
            font-size: calc(16px + 0.3vw);
          }
          @media (min-width: 1100px) and (max-width: 1300px) {
            font-size: calc(16px + 0.2vw);
          }
          @media (min-width: 992px) and (max-width: 1100px) {
            font-size: 1rem;
          }
        }
        .linkfleche {
          margin-top: 1em;
        }
        .french-warning {
          letter-spacing: 0.1rem;
          font-family: $font-inter-medium;
        }
      }
    }
    .card-color {
      background-color: $gray-lighter;
      .img-verticale {
        width: 80% !important;
        height: intrinsic;
      }
    }
  }
}

/* 
* Page - Programmation
*/
.programmation {
  .image {
    @media (min-width:590px) and (max-width: 992px) {
      min-height: 400px;
    }
   
    &.horsmurs {
      background-image: url("../img/pages/horsmurs.jpg");
    }
    &.concours {
      background-image: url("../img/pages/concours.jpeg");
    }
    &.ouvreart {
      background-image: url("../img/pages/raphaelle-de-groot.jpg");
    }
  }
  .card-mem {
    .card-container {
      @media screen and (min-width: 1666px) {
        padding: 9% 2.5em 8.5% 0;
      }
      @media screen and (max-width: 1665px) {
        padding: 9% 2.5em 8.5% 2.5em;
      }
      @media screen and (max-width: 1200px) {
        padding: 9% 1.5em 8.5% 1.5em;
      }
      .top-card {
        margin-bottom: 1.5em;
        .small {
          @media screen and (max-width: 1200) {
            margin-bottom: 1.5em;
          }
        }
        .french-warning {
          font-size: $font-size-small;
          font-family: $font-inter-medium;
        }
        .credit-img {
          font-size: 0.9rem;
          margin-top: 1rem;
        }
        // .grand-titre {
        //   //4.375rem;
        //   font-size: calc(40px + 1.5vw);
        //   margin-bottom: 0;
        //   @media (min-width: 1600px) and (max-width: 1700px) {
        //     font-size: calc(40px + 1.1vw);
        //   }
        //   @media (min-width: 1400px) and (max-width: 1600px) {
        //     font-size: calc(40px + 0.5vw);
        //   }
        //   @media (min-width: 1201px) and (max-width: 1400px) {
        //     font-size: calc(40px + 0.2vw);
        //   }
        //   @media (min-width: 1100px) and (max-width: 1200px) {
        //     font-size: $h1-font-size; // 40px
        //   }
        //   @media (min-width: 992px) and (max-width: 1100px) {
        //     font-size: 1.64rem;
        //   }
        // }
      }
      .text-card {
        p,
        a {
          font-size: calc(16px + 0.48vw);
          @media (min-width: 1500px) and (max-width: 1700px) {
            font-size: calc(16px + 0.35vw);
          }
          @media (min-width: 1400px) and (max-width: 1500px) {
            font-size: calc(16px + 0.25vw);
          }
          @media (min-width: 1300px) and (max-width: 1400px) {
            font-size: calc(16px + 0.2vw);
          }
          @media (min-width: 992px) and (max-width: 1300px) {
            font-size: 1rem;
          }
          .fa-external-link-alt {
            opacity: 0;
            font-size: $font-size-medium-var;
            transition: all 1s ease;
          }
          .fa-file-pdf {
            margin: 0 0.5em 0 1em;
            color: $red-dark;
            font-size: $font-size-medium-var;
          }
          .detail {
            font-size: $font-size-medium-var;
          }
        }
        .card-date {
          font-family: $font-inter-medium;
        }
        .pdf-content {
          p {
            margin-bottom: 0;
            br {
              @media (min-width: 420px) {
                display: none;
              }
            }
          }
        }
        .linkfleche {
          margin-top: 1em;
        }
        .french-warning {
          margin-bottom: 1rem;
          letter-spacing: 0.1rem;
          font-family: $font-inter-medium;
        }
        .footer-card {
          a {
            &:hover {
              .fa-file-pdf,
              .fa-external-link-alt {
                color: $red-light;
              }
              .fa-external-link-alt {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .card-color {
      background-color: $gray-lighter;
      .img-verticale {
        width: 68% !important;
        height: intrinsic;
      }
    }
  }
}

/* 
* Page - Balados
*/
.balados {
  .balados-wrapper {
    padding: 5% 2.5em;
    @media (max-width: 1200px) {
      padding: 5% 1.5em;
    }
    h2 {
      font-family: $font-oswald-light;
      font-size: 3rem;
    }
    .balados-content {
      .card-column {
        display: block;
        column-count: 2;
        @media (max-width: 992px) {
          column-count: 1;
          display: flex;
        }
        .card-col-5 {
          padding-top: 15%;
          @media (max-width: 1400px) {
            padding-top: 7%;
          }
        }
        //liste Balados
        .card-col-4 {
          .playlist-balados,
          .playlist-balados-youtube {
            flex-direction: column;
            font-size: 16px;
            @media (min-width: 998px) and (max-width: 1400px) {
              flex-direction: row;
              .col-playlist-1,
              .col-playlist-2 {
                width: 50%;
              }
              .col-playlist-1 {
                padding-right: 2em;
              }
              .col-playlist-2 {
                padding-left: 2em;
              }
            }
            @media (min-width: 768px) and (max-width: 998px) {
              align-items: center;
              .col-playlist-1,
              .col-playlist-2 {
                width: 80%;
                padding: 0 2em;
              }
            }
            a {
              &.item {
                text-decoration: none;
                padding: 0.75rem 0;
                border-bottom: 1px solid #ccc;
                font-size: 16px;
                .fa-play-circle {
                  font-size: 1.5em;
                  font-family: $font-awesome;
                  color: $gray-darker;
                }
                &:hover {
                  text-decoration: none;
                  .fa-play-circle {
                    color: $red-light;
                  }
                  .fa-external-link-alt {
                    display: inline-block;
                  }
                }
                .fa-external-link-alt {
                  display: none;
                  margin-left: 1em;
                  font-size: 14px;
                  @media (min-width: 992px) and (max-width: 1100px) {
                    font-size: 13px;
                  }
                }
                .badge-audio {
                  color: $red-light;
                  margin-right: 1em;
                  font-family: $font-inter-light;
                  font-size: 14px;
                  display: inline-block;
                }
                .time-audio {
                  font-size: 14px;
                  font-family: $font-inter-light;
                  padding: 0 1em;
                }
                &.audio-active {
                  color: $red-light;
                }
                @media (min-width: 992px) and (max-width: 1100px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
        @media (min-width: 1200px) and (max-width: 1400px) {
          display: flex;
          .card-col-1,
          .card-col-2,
          .card-col-3 {
            max-width: 50%;
            flex: 0 0 50%;
          }
          .card-col-3 {
            margin-top: -15%;
          }
        }
        @media (min-width: 992px) and (max-width: 1200px) {
          display: flex;
          .card-col-1,
          .card-col-2,
          .card-col-3 {
            max-width: 50%;
            flex: 0 0 50%;
          }
          .card-col-3 {
            margin-top: -13%;
          }
        }
        @media (min-width: 768px) and (max-width: 992px) {
          display: flex;
          .card-col-1,
          .card-col-2 {
            max-width: 50%;
            flex: 0 0 50%;
          }
          .card-col-3 {
            width: 100vw;
            margin-top: 7%;
          }
        }
        @media (max-width: 768px) {
          .card-col-3 {
            padding-top: 0.5em;
          }
        }
      }
      .balados-red {
        margin-bottom: 3em;
        @media (max-width: 768px) {
          margin-bottom: 2em;
        }
      }
      .balados-theme {
        margin-top: 5%;
        margin-bottom: 3em;
        @media (max-width: 768px) {
          margin-bottom: 2em;
        }
      }
      .audio-list {
        padding-top: 5%;
        .audio-wrapper {
          display: none;
          padding: 0 1.5em 1em 1.5em;
          @media (max-width: 576px) {
            padding: 0.5em 1.5em;
          }
          #audio-balado,
          #audioSourceBalado {
            &:focus {
              outline: none;
            }
          }
          &.sticky {
            position: fixed;
            top: 97px;
            background-color: $red-light;
            z-index: 12;
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            padding: 0.8em 1.5em;
            display: flex;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            .wrapper-title-audio {
              display: flex;
              align-items: center;
              // margin: 0;
              // justify-content: center;
              // flex: 0 0 100%;
              // max-width: 100%;
              // padding-bottom: 0;
              @media (min-width: 768px) and (max-width: 992px) {
                padding-bottom: 0;
              }
              @media (max-width: 800px) {
                flex-direction: column;
              }
              @media (max-width: 567px) {
                // flex: 0 0 70%;
                // max-width: 70%;
              }
              #title-audio {
                display: inline-flex;
                margin-right: 1em;
                font-size: 16px;
                font-family: $font-inter-medium;
                color: $gray-lighter;
                @media (max-width: 768px) {
                  display: flex;
                  justify-content: center;
                  margin-right: 0;
                  line-height: 1.2;
                  margin-bottom: 0.3em;
                  max-width: 300px;
                }
              }
              audio {
                height: 45px;
                min-width: 250px;
              }
              .btn-close {
                width: 35px;
                height: 35px;
                position: absolute;
                right: 2.5em;
                @media (max-width: 1200px) {
                  right: 1.5em;
                }
                @media (min-width: 567px) and (max-width: 798px) {
                  right: 1.5em;
                  top: 1.5em;
                }
                @media (max-width: 567px) {
                  width: 30px;
                  height: 30px;
                  right: 0.5em;
                }
                @media (max-width: 420px) {
                  width: 25px;
                  height: 25px;
                  top: 0.5em;
                }
                @media (max-width: 320px) {
                  width: 20px;
                  height: 20px;
                  top: 0.25em;
                }
                .close-mem {
                  position: relative;
                  display: inline-block;
                  width: 35px;
                  height: 35px;
                  top: 0;
                  left: 0;
                  overflow: hidden;
                  transition: transform 1s;
                  &::after,
                  &::before {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    background-color: $gray-lighter;
                  }
                  &::before {
                    transform: rotate(45deg);
                  }
                  &::after {
                    transform: rotate(-45deg);
                  }
                  @media (max-width: 567px) {
                    width: 30px;
                    height: 30px;
                  }
                  @media (max-width: 420px) {
                    width: 25px;
                    height: 25px;
                  }
                  @media (max-width: 320px) {
                    width: 20px;
                    height: 20px;
                  }
                }
                &:hover {
                  .close-mem {
                    &::after,
                    &::before {
                      background-color: $black-mem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    img {
      padding-top: 10%;
      @media (max-width: 768px) {
        padding: 7% 0 0 0;
      }
    }
    .source-img {
      font-size: $font-size-small;
      padding: 10px;
    }
    .download-content {
      a {
        .fa-file-download {
          margin: 0 1em;
          color: $red-dark;
        }
        &:hover {
          .fa-file-download {
            color: $red-light;
          }
        }
        @media (max-width: 567px) {
          font-size: 16px;
        }
      }
      .detail {
        font-size: 16px;
        @media (max-width: 567px) {
          font-size: 14px;
        }
      }
      br {
        @media (min-width: 321px) {
          display: none;
        }
      }
    }
  }
}

.download-content {
  a {
    .fa-file-download {
      margin: 0 1em;
      color: $red-dark;
    }
    &:hover {
      .fa-file-download {
        color: $red-light;
      }
    }
    @media (max-width: 567px) {
      font-size: 16px;
    }
  }
  .detail {
    font-size: 16px;
    @media (max-width: 567px) {
      font-size: 14px;
    }
  }
}

/*
* Header Sous Pages Event Programming, Winner contest, Activ educ, Location de salles
*/
body:not(.home-page) {
  &.event,
  &.activeduc,
  &.winners,
  &.visite {
    .jumbotron {
      background-color: $gray-lighter;
      height: auto;
      padding-bottom: 0;
      .jumbotron-content {
        width: 100%;
        height: auto;
        position: relative;
        padding-bottom: 0;
        .jumbotron-top {
          margin-bottom: 1.5rem;
          .text-uppercase,
          .red-text {
            margin-right: 1.875rem;
          }

          @media (max-width: 780px) {
            font-size: $font-size-base;
          }
          .disciplines {
            display: flex;
            i {
              padding-left: 0.5rem;
            }
            @media (max-width: 581px) {
              flex-direction: column;
              .separator {
                display: none;
              }
            }
          }
          .class-level {
            display: flex;
            @media (max-width: 380px) {
              flex-direction: column;
            }
            .pipe {
              @media (max-width: 380px) {
                display: none;
              }
            }
          }
        }
        h1 {
          color: $black-mem;
          font-size: $font-size-xlarge;
        }
      }
    }
    section {
      &.top {
        padding: 1.5em 2.5em;
        @media (max-width: 1200px) {
          padding: 1.5em;
        }
      }
    }
  }
}

/*
* Page - Winners contest
*/
body:not(.home-page) {
  &.winners {
    section {
      &.content-winners {
        padding: 3em 2.5em 5em 2.5em;
        @media (max-width: 1200px) {
          padding: 2em 1.5em 5em 1.5em;
        }
        .border-bottom-md {
          @media (max-width: 991px) {
            border-bottom: 1px solid $gray-lighter;
            padding-bottom: 2em;
          }
        }
        .detail-img {
          font-size: $font-size-small;
          .detail-title {
            font-size: $font-size-base;
          }
        }
        .credit {
          br {
            @media (min-width: 480px) {
              display: none;
            }
          }
        }
      }
    }
  }
}

/*
* Page - Event, Activ educ detail, Info pratiques, Location de salles
*/
body:not(.home-page) {
  &.event,
  &.activeduc,
  &.visite {
    section {
      &.content-event {
        margin-bottom: 2rem;
        .container-fluid {
          padding: 4em 2.5em 4em 2.5em;
          @media (max-width: 1200px) {
            padding: 0;
          }
        }
        // .top-container {
        // 	padding-bottom: 1.5em;
        // 	@media (max-width: 1200px) {
        // 		padding: 1.5em;
        // 	}
        // 	.fas {
        // 		font-size: $font-size-18;
        // 	}
        // 	@media (min-width: 433px) {
        // 		br {
        // 			display: none;
        // 		}
        // 	}
        // }
        .col-left {
          .credit-img {
            font-size: $font-size-small;
            margin-top: 1rem;
            @media (max-width: 1200px) {
              padding: 0 1.5em 0 2em;
            }
          }
          .info-text {
            @media (max-width: 1200px) {
              padding: 0 1.5em 1.5em 1.5em;
            }
            .block-video {
              margin-top: 3rem;
            }
          }
          .promo {
            // background-color: $gray-lighter;
            padding: 2rem 2rem 1rem 2rem;
            // color: $black-mem;
            @media (max-width: 560px) {
              padding: 1.5rem;
            }
            h3 {
              padding-top: 0;
            }
            a {
              color: #0c5460;
              &:hover {
                color: $red-light;
              }
            }
          }
          .pdf-content {
            p {
              margin-bottom: 0;
              .fa-file-pdf {
                margin: 0 0.5em 0 1em;
                color: $red-dark;
                font-size: $font-size-medium-var;
              }
              .detail {
                font-size: $font-size-small;
              }
            }
          }
        }
        .col-right {
          h3 {
            font-size: $h3-var;
          }
          .notice {
            .detail {
              font-size: $font-size-base;
            }
          }
          .info {
            background-color: $gray-lighter;
            padding: 2rem;
            color: $black-mem;
            @media (max-width: 560px) {
              padding: 1.5rem;
            }
            .notice-info {
              font-size: $font-size-base;
              width: fit-content;
              .text-bold {
                @media (min-width: 476px) and (max-width: 560px) {
                  padding: 0 3rem;
                }
                @media (min-width: 1656px) {
                  padding: 0 3.1rem;
                }
              }
            }
            .booking {
              margin: 1.5rem 0;
              .btn {
                font-size: $font-size-medium-var;
                background-color: $red-dark;
                margin: 0;
                color: $white;
                border-color: $red-dark;
                font-family: $font-inter-medium;
                width: 100%;
                padding: 1rem;
                &:hover {
                  color: $red-dark;
                  background-color: $white;
                  border-color: $red-dark;
                }
                @media (max-width: 1200px) {
                  padding: 1rem 7rem;
                  width: auto;
                }
                @media (max-width: 560px) {
                  padding: 1rem;
                  width: 100%;
                }
                // .fas {
                // 	display: inline-block;
                // 	vertical-align: middle;
                // 	padding-bottom: 0.4rem;
                // 	font-size: $font-size-18;
                // }
              }
            }
          }
          .booking-location {
            margin: 1.5rem 0;
            .btn {
              margin: 0;
              width: 100%;
              border-radius: 10px;
              border: 1px solid #0f0f0f;
              padding: 0.5rem 1.5rem;
              @media (max-width: 1200px) {
                padding: 1rem 7rem;
                width: auto;
              }
              @media (max-width: 560px) {
                padding: 1rem;
                width: 100%;
              }
            }
            .btn-lg {
              font-size: 2rem;
            }
          }
          .horaire {
            padding: 2rem 2rem 0.5rem 2rem;
            @media (max-width: 560px) {
              padding: 1.5rem 1.5rem 0.5rem 1.5rem;
            }
            .date {
              font-size: $font-size-medium;
              font-family: $font-inter-medium;
              font-weight: 700;
              margin: 1.5rem 0 1rem;
            }
            .detail-time .text-bold,
            .total-time {
              font-size: $font-size-base;
            }
            .detail {
              font-size: $font-size-base;
              .lang-event {
                width: 10rem;
                &:lang(en) {
                  margin-right: 0.5rem;
                }
              }
            }
            .notice p {
              font-size: $font-size-base;
              display: flex;
              &::before {
                content: "*";
                margin-right: 0.3rem;
              }
            }
          }
          .map {
            padding: 2rem 2rem 0.5rem 2rem;
            @media (max-width: 560px) {
              padding: 1.5rem 1.5rem 0.5rem 1.5rem;
            }
            h3 {
              font-size: $h3-var;
            }
            .detail {
              font-size: $font-size-base;
            }
          }
          .detail-access {
            padding: 2rem 2rem 0.5rem 2rem;
            @media (max-width: 560px) {
              padding: 1.5rem 1.5rem 0.5rem 1.5rem;
            }
            .detail {
              font-size: $font-size-base;
            }
          }
        }
      }
    }
  }
}

body:not(.home-page) {
  &.informations {
    .jumbotron {
      .jumbotron-content {
        h1 {
          color: $gray-lighter;
          br {
            display: none;
            @media (max-width: 920px) {
              display: block;
            }
          }
        }
      }
    }

    section {
      &.content-info {
        margin-bottom: 2rem;
        .container-fluid {
          padding: 4em 2.5em 4em 2.5em;
          @media (max-width: 1200px) {
            padding: 0;
          }
        }
        .col-left {
          .info-text {
            @media (max-width: 1200px) {
              padding: 0 1.5em 1.5em 1.5em;
            }
          }
          .btn {
            font-size: 1.75rem;
            @media (max-width: 1200px) {
              font-size: 1.4rem;
            }
          }
          .booking-btn {
            .btn {
              width: auto;
            }
          }
        }
        .col-right {
          h3 {
            font-size: $h3-var40;
            font-family: $font-inter-light;
          }
          h4 {
            font-size: $h4-var28;
            font-family: $font-inter-light;
          }
          .map {
            padding: 2rem 2rem 0.5rem 2rem;
            @media (max-width: 560px) {
              padding: 1.5rem 1.5rem 0.5rem 1.5rem;
            }
            .detail {
              font-size: $font-size-base;
            }
          }
          .detail-access {
            padding: 2rem 2rem 0.5rem 2rem;
            @media (max-width: 560px) {
              padding: 1.5rem 1.5rem 0.5rem 1.5rem;
            }
            .detail {
              font-size: $font-size-base;
            }
          }
        }
      }
    }
  }
}

/*
* Preloader Spiner
*/

.preloader {
  align-items: center;
  background-color: $gray-lighter;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s linear;
  width: 100%;
  z-index: 1100;
  .loader {
    border: 20px solid $white;
    border-radius: 50%;
    border-top: 1rem solid $red-dark;
    width: 150px;
    height: 150px;
    animation: spin 1s linear infinite;
    &.center {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/*
* Footer
*/
footer {
  background-color: $gray-medium;
  padding: 2.5em 1.5em;
  @media screen and (max-width: 375px) {
    padding: 2.3em 1em;
  }
  p,
  a {
    font-family: $font-inter-regular;
    text-decoration: none;
  }
  .linkfleche {
    margin-top: 0;
    &.big-text {
      a {
        padding-left: 0;
        @media screen and (max-width: 1200px) {
          font-size: 2.1rem;
          .fleche {
            margin-left: 1.5em;
          }
        }
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
        @media screen and (max-width: 420px) {
          font-size: 1.5rem;
          .fleche {
            margin-left: 1.2em;
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }
  .display-mobile {
    display: none;
    @media screen and (max-width: 375px) {
      font-size: 1.72rem;
    }
  }
  .small {
    // margin-bottom: 1rem;
    @media (min-width: 567px) {
      br {
        display: none;
      }
    }
  }
  #footer-media {
    .media-brand {
      margin-bottom: 3em;
      ul {
        li {
          a {
            margin-right: 1em;
            color: $black-mem;
            font-size: $h1-font-size; //40px
            @media screen and (max-width: 1375px) {
              font-size: 2.1rem; //38px
              margin-right: 0.7em;
            }
            @include media-breakpoint-down(lg) {
              font-size: $h2-font-size;
            }
            @media screen and (max-width: 1170px) {
              font-size: 1.8rem;
            }
            @include media-breakpoint-down(md) {
              font-size: $h3-font-size; //28px
            }
            &:hover {
              color: $red-light;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  #footer-info {
    .location {
      margin-bottom: 3em;
    }
    .gen-info {
      .tel,
      a {
        font-size: $h3-font-size; //32px;
      }
    }
  }
  .logo-mtl {
    mask-image: url("../img/rosace.svg");
    width: 60px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    margin-bottom: 1em;
    background-color: $red-light;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: white;
    }
  }
  .logo-mtl-mobile {
    display: none;
  }
  #footer-last {
    padding-top: 2em;
    a,
    span {
      color: $gray-darker;
      &:hover {
        color: $black-mem;
      }
      @media screen and (max-width: 1375px) {
        font-size: $font-size-base; //16px
      }
      @media screen and (max-width: 1200px) {
        font-size: $font-size-small; //14px
      }
    }
    .footer-last-left {
      a {
        text-decoration: underline;
      }
    }
  }
  @include media-breakpoint-down(md) {
    #footer-media,
    #footer-info,
    .logo-mtl-mobile,
    .footer-last-left,
    .city {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    p,
    ul {
      text-align: center;
      li {
        margin-bottom: 1em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .display-mobile {
      display: block;
      font-family: $font-inter-regular;
      // margin-bottom:1em;
      font-size: $h2-font-size;
    }
    #footer-media {
      .newsletter {
        .small {
          display: none;
        }
      }
    }
    #footer-info {
      margin-top: 3em;
      .small {
        display: none;
      }
    }
    .logo-mtl-container {
      display: none;
    }
  }
}

.error-page,
.confirmed-page {
  .container {
    height: 50vh;
  }
}

.linkfleche {
  &.bganimate {
    .long-text {
      br {
        display: none;
      }
      @media (max-width: 460px) {
        border-bottom: none;
        text-decoration: underline;
        br {
          display: block;
        }
      }
    }
  }
}

// Collectrice programmation et activ educ - Hors les murs, Expositions
.card-list {
  padding-bottom: 3rem;
  @media (min-width: 992px) {
    padding: 5.813rem 2.5em 4% 2.5em; // 93px
  }
  .row {
    padding-bottom: 3.125rem; // 50px
    .media {
      width: 100%;
      min-height: 260px;
      @media (min-width:560px) and (max-width:992px){
         min-height: 400px;
      }
      @media (min-width: 992px) {
        // width: 518px;
        min-height: 254px;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
      }
      background-position: center;
      background-size: cover;
      &.velo {
        background-image: url("../img/pages/velos-citoyens.jpg");
      }
      &.milton {
        background-image: url("../img/pages/milton-parc-crop.jpg");
      }
      &.quartier {
        background-image: url("../img/pages/quartier-chinois-crop.jpg");
      }
      &.quartierfr {
        background-image: url("../img/pages/veloquartierfr-crop.jpg");
      }
      &.sens {
        background-image: url("../img/pages/5sens-crop.jpg");
      }
      &.chainon {
        background-image: url("../img/pages/expo-chainon.jpg");
      }
      &.detours {
        background-image: url("../img/pages/detour.jpg");
      }
      &.montreal {
        background-image: url("../img/pages/fox.jpg");
      }
      &.konvesasyon {
        background-image: url("../img/pages/konvesasyon-crop.png");
      }
      &.voixchainon {
        background-image: url("../img/pages/voix-chainon-crop.jpg");
      }
      &.souvenirs {
        background-image: url("../img/pages/souvenirs-quartiers.jpg");
      }
      &.dia-muertos {
        background-image: url("../img/pages/dia-muertos.jpg");
      }
      &.cartographier {
        background-image: url("../img/pages/cartographies.jpg");
      }
      &.cinemania {
        background-image: url("../img/pages/cinemania.jpg");
      }
      &.violence-femmes {
        background-image: url("../img/pages/violence-femmes.png");
      }
      &.marmitonnes {
        background-image: url("../img/pages/marmitonnes.jpg");
      }
      &.hippocampe {
        background-image: url("../img/pages/constellations.jpg");
      }
      &.toujoursla {
        background-image: url("../img/pages/toujoursla.jpg");
      }
      &.ancetres {
        background-image: url("../img/pages/bioarcheologie.jpg");
      }
      &.bricozero {
        background-image: url("../img/pages/bricozero.jpg");
      }
       &.bricozeroete {
        background-image: url("../img/pages/brico-zero-ete.jpeg");
      }
      &.bricozeroautomne {
        background-image: url("../img/pages/bricozero-automne.jpg");
        background-position-y: 10%;
      }
      &.expomtl {
        background-image: url("../img/pages/montreal.jpg");
      }
      &.voxpop {
        background-image: url("../img/pages/vox-pop.jpg");
      }
      &.chinatown {
        background-image: url("../img/pages/chinatown.jpg");
      }
      &.kiosquemem {
        background-image: url("../img/pages/kiosque-mem.jpg");
      }
      &.pouzzafest {
        background-image: url("../img/pages/pouzzafest.jpg");
      }
      &.adopouzzafest {
        background-image: url("../img/pages/ado-pouzzafest.jpg");
      }
      &.discovermtl {
        background-image: url("../img/pages/discover-montreal.jpg");
      }
      &.wheelclub {
        background-image: url("../img/pages/wheel-club.jpg");
      }
      &.milesouliers {
        background-image: url("../img/pages/mile-souliers-poster.jpeg");
      }
      &.histoiresoubliees {
        background-image: url("../img/pages/histoires-oubliees.jpg");
      }
      &.archives {
        background-image: url("../img/pages/archives.jpg");
        @media (min-width: 992px) {
          background-position: center 35%;
        }
        background-position: center 20%;
      }
      &.nuitblanche {
        background-image: url("../img/pages/nuitblanche.jpg");
        background-position-y: 45%;
      }
      &.adotag {
        background-image: url("../img/pages/ado-tag.jpg");
        background-position: center 19%;
      }
       &.adochaussures {
        background-image: url("../img/pages/ado-chaussures.jpg");
        background-position: center 20%;
      }
      &.archivesquartierchinois {
       background-image: url("../img/pages/archives-quartier-chinois.jpg");
      }
      &.archiveskahnawake {
       background-image: url("../img/pages/archives-kahnawake.png");
        @media (min-width: 556px) and (max-width: 992px){
          background-position: center 35%;
        }
      }
      &.enseignes {
        background-image: url("../img/pages/enseignes.jpg");
         @media (min-width: 556px) and (max-width: 992px){
          background-position: center 25%;
        }
      }
      &.portugaise {
        background-image: url("../img/pages/pierres-memoires.jpeg");
        background-position: center 10%;
      }
      &.miltonparc {
        background-image: url("../img/pages/milton-parc-expo.jpg");
      }
      &.genderblender{
        background-image: url("../img/pages/gender-blender.jpg");
      }
      &.fruitdefendus {
         background-image: url("../img/pages/fruits-defendus.jpg");
      }
      &.jardintropical {
        background-image: url("../img/pages/bananier.jpg");
      }
      &.festivaltrad {
        background-image: url("../img/pages/festival-trad.jpg");
      }
      &.tonquartier {
        background-image: url("../img/pages/ton-quartier.jpg");
      }
      &.montrealcartes {
        background-image: url("../img/pages/montreal-cartes-en-main.jpg");
      }
      &.valise {
        background-image: url("../img/pages/valise.jpg");
      }
      &.etincelle {
        background-image: url("../img/pages/etincelle-citoyenne.jpg");
      }
      &.mtlaccessible {
        background-image: url("../img/pages/montreal-accessible.jpg");
      }
      &.dossiereducation {
         background-image: url("../img/pages/dossier-education.jpg");
      }
      &.mtlmetropole {
        background-image: url("../img/pages/montreal-metropole.jpg");
      }
      &.montreal101:lang(en) {
        background-image: url("../img/pages/montreal-101-en.jpg");
      }
       &.montreal101 {
        background-image: url("../img/pages/montreal-101.jpg");
      }
      &.detourssouliers {
        background-image: url("../img/pages/detourssouliers.jpg");
      }
      &.apprenticitoyen {
        background-image: url("../img/pages/apprenti-citoyen.jpg");
      }
      &.voixautochtones {
        background-image: url("../img/pages/voixautochtones.jpg");
        background-position: top;
      }
      &.taxi {
        background-image: url("../img/pages/taxi.jpg");
      }
      &.halloween {
        background-image: url("../img/pages/halloween.jpg");
        // background-position: bottom;
         background-position-y: 80%;
      }
      &.miltonparcfutur {
        background-image: url("../img/pages/milton-parc-futur.jpg");
      }
      &.douceprovidence {
         background-image: url("../img/pages/douce-providence.jpg");
      }
      &.mtlfilmsarchives {
         background-image: url("../img/pages/wow-audiovisuel.jpg");
      }
      &.constellations {
        background-image: url("../img/pages/constellations-hippocampe.jpg");
      }
      &.festivalnovembre {
        background-image: url("../img/pages/novembre-couleur.jpg");
      }
      &.entremetteuse {
        background-image: url("../img/pages/entremetteuse.jpg");
      }
      &.activdeco {
        background-image: url("../img/pages/activ-deco.jpg");
      }
      &.mots {
        background-image: url("../img/pages/mots.jpg");
      }
      &.atelierperlage {
        background-image: url("../img/pages/atelier-perlage.jpg");
        background-position: bottom 25% center;
      }
      &.offmundial {
         background-image: url("../img/pages/offmundial.jpg");
         background-position-y: 25%;
      }
      &.memfamille {
         background-image: url("../img/pages/memfamille.jpg");
         background-position-y: 30%;
      }
      &.daycareholiday {
        background-image: url("../img/pages/daycare-holiday.jpg");
         background-position-y: 30%;
      }
      &.salonenville {
        background-image: url("../img/pages/salon-ville.png");
        background-position: top;
      }
      &.rencontres {
        background-image: url("../img/pages/rencontres.jpg");
      }
      &.wowtaxi {
        background-image: url("../img/pages/wow-taxi.jpg");
      }
      &.rechauffemoi {
        background-image: url('../img/pages/rechauffemoi.jpg');
      }
      &.calendrieravent {
        background-image: url('../img/pages/calendrier-avent.jpg');
      }
      &.hiver {
        background-image: url('../img/pages/glissades.jpg');
        background-position-y: 75%;
      }
      &.menusmontreal {
        background-image: url('../img/pages/menus-montreal.jpg');
      }
      &.refugies {
        background-image: url('../img/pages/refugies.jpg');
      }
      &.histoirenoirs {
        background-image: url('../img/pages/histoire-noirs.jpg');
        background-position: top;
      }
      &.stvalentine {
        background-image: url('../img/pages/detours.jpg');
      }
      &.madelaine {
        background-image: url('../img/pages/madelaine.jpg');
        background-position-y: 20%;
      }
    } 
    .card-text {
      padding-left: 1.875rem;
      .card-text-info {
        @media (min-width: 992px) {
          border-top: 1px solid $gray-dark;
        }
        padding-top: 1.5rem;
      }
      .top-card {
        .small,
        .date {
          padding-right: 1.875rem;
          @media (max-width: 564px) {
            padding-right: 1rem;
          }
        }
        .date {
          font-size: $font-size-18;
        }
        .detail {
          font-size: 0.9rem;
          @media (max-width: 380px) {
            flex-direction: column;
            .pipe {
                display: none;
              }
          }
          &.all-list {
            @media (max-width: 500px) {
              flex-direction: column;
              .pipe {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.booking-btn {
  margin: 1.5rem 0;
  .btn {
    font-size: $font-size-medium-var;
    background-color: $red-dark;
    margin: 0;
    color: $white;
    border-color: $red-dark;
    font-family: $font-inter-medium;
    width: 100%;
    padding: 1rem;
    &:hover {
      color: $red-dark;
      background-color: $white;
      border-color: $red-dark;
    }
    @media (max-width: 1200px) {
      padding: 1rem 7rem;
      width: auto;
    }
    @media (max-width: 560px) {
      padding: 1rem;
      width: 100%;
    }
  }
}

.disciplines {
  display: flex;
  i {
    padding-left: 0.5rem;
  }
  @media (max-width: 581px) {
    flex-direction: column;
    .separator {
      display: none;
    }
  }
}

.disciplines-m {
  display: flex;
  i {
    padding-left: 0.5rem;
  }
  @media (max-width: 875px) {
    flex-direction: column;
    .separator {
      display: none;
    }
  }
}

.disciplines-l {
  display: flex;
  i {
    padding-left: 0.5rem;
  }
  @media (max-width: 1047px) {
    flex-direction: column;
    .separator {
      display: none;
    }
  }
}
