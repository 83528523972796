//
// Variables
// --------------------------------------------------

//== Typography
// @import url('https://rsms.me/inter/inter.css');

@font-face {
	font-family: "Inter-Light";
  src: url("../fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; 
  font-display: swap;  
}
@font-face {
	font-family: "Inter-Regular";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");	
  font-weight: normal;
  font-style: normal;
  font-display: swap;	     
}
@font-face {
	font-family: "Inter-Medium";
  src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");	
  font-weight: 500;
  font-style: normal;
  font-display: swap;	     
}
@font-face {
	font-family: "Inter-Bold";
  src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");	
  font-weight: bold;
  font-style: normal;
  font-display: swap;	     
}

@font-face {
	font-family: "Oswald-Light";
  src: url("../fonts/Oswald/Oswald-Light.ttf") format("truetype");
  font-weight: 300;	 
  font-style: normal;
  font-display: swap;	  
}

// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
$inter : 'Inter', Arial, sans-serif;

//@import url("https://use.fontawesome.com/releases/v5.11.2/css/all.css");
$fa-font-path: "../fontawesome/webfonts";
$font-awesome: "Font Awesome 5 Free";

// Type - families
$font-inter-light: 'Inter-Light', Arial, sans-serif;
$font-inter-medium: 'Inter-Medium', Arial, sans-serif;
$font-inter-bold: 'Inter-Bold', Arial, sans-serif;
$font-inter-regular: 'Inter-Regular', Arial, sans-serif;

$font-oswald-light: 'Oswald-Light', Arial, sans-serif;

$font-family-base: $inter;
$font-family-sans-serif:   Arial, sans-serif;

// Type - sizes
//see http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
$font-size-base:      1rem; //16px
$font-size-base-mem:  1.25rem;  //20px

// $font-size-xxlarge:   4.375rem; //70px
//$font-size-xlarge:    3.75rem;// 60px
// fluid typography - font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
$font-size-large: calc(28px + (48 - 28) * ((100vw - 300px) / (1600 - 300))); // 48px
$font-size-xlarge: calc(34px + (60 - 34) * ((100vw - 300px) / (1600 - 300))); // 60px
$font-size-xxlarge: calc(40px + 1.57vw); //h1 - 70px
$h3-var: calc(24px + (28 - 24) * ((100vw - 300px) / (1600 - 300))); // 60px
$h3-var40: calc(28px + (40 - 28) * ((100vw - 300px) / (1920 - 300))); // 40px
$h4-var28: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300))); // 28px

$font-size-h5: 1.4rem; //22px
$font-size-medium: 1.25rem; //20px
$font-size-18: 1.125rem; //18px
$font-size-small: 0.875rem; //14px
$font-size-xmedium: calc(16px + 0.626vw);//28px
// $font-size-medium-var: calc(16px + 0.25vw);//20.7px
$font-size-medium-var: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));

$h1-font-size:                $font-size-base * 2.5 !default; // 40px
$h2-font-size:                $font-size-base * 2 !default; // 32px
$h3-font-size:                $font-size-base * 1.75 !default; // 28px
$h4-font-size:                $font-size-base * 1.5 !default; // 24px
$h5-font-size:                $font-size-base * 1.4 !default; // 20px
$h6-font-size:                $font-size-base !default; // 16px

//Color system
$white: #fff !default;
$black-mem: #0F0F0F!default;

// Colors
$red-dark: #820000;
$red-light: #DF0000;

$gray-darker: #707070;
$gray-dark: #AAAAAA;
$gray-medium: #D1D1D1;
$gray-lighter: #F2F2F2;


$grid-breakpoints: (
  xs: 0, // Extra small screen / phone
  ssm: 375px,
  sm: 576px,  // Small screen / phone
  md: 768px, // Medium screen / tablet
  lg: 992px, // Large screen / desktop
  xl: 1200px, // Extra large screen / wide desktop
  xxl:1920px
);

$container-max-widths: (
  ssm: 324px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl:1600px,
  xxxl:1900px
);
