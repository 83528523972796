// Navbar
.navbar {
  padding: 1.5em 2.5em;
  transition:500ms ease;
  background:transparent;
  @media (max-width: 1200px) {
    padding: 1.5em;
  }
  @media (max-width: 414px) {
    padding: 1em 1.5em;
  }
  .navbar-brand {
    @media (min-width: 1325px) and (max-width: 1500px) {
      max-width:30%
    }
    @media (min-width: 1200px) and (max-width: 1325px) {
      max-width:27%
    }
    @media (max-width: 1200px-1px) {
      max-width:35%
    }
    .signature-mobile {
      display:none;
      transition: all .5s ease
    }
    .logo-mem { 
      display:none;
      transition: all .5s ease
    }
    @include media-breakpoint-down(lg) {
      .signature {
        display:none;
        transition: all .5s ease
      }
      .signature-mobile {
        display:block;
        transition: all .5s ease
      }
    }
    .signature {
      max-width: 100%;
      margin-top: -0.6em;
    }

  }
  .navbar-nav {
    .nav-item {
      &.active {
        .nav-link {
          ////boullet point display
          &:after {
            opacity: 1;
          }
          @media (min-width: 1200px) {
            flex-direction: column;
          }
        }
      }
      .nav-link {
        font-family: $font-inter-medium;
        color:  $red-light;
        font-size: 1.25rem; // 20px
        text-decoration: none;
        @media (min-width: 1200px) and (max-width: 1325px) {
          font-size: 1.02rem;
        }
        @media (min-width: 1325px) and (max-width: 1425px) {
          font-size: 1.1rem;
        }
        //boullet point
        &:after {
          content: "";
          opacity: 0;
          width: .3rem;
          height: .3rem;
          background-color: $red-light;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin: auto 0.5em;
          @media (min-width: 1200px) {
            margin: auto;
            display: flex;
          }
        }
        &:hover {
          &:after {
            opacity: 1;
            transition-delay: 0s;
          }
        }
        &.ticket-link {
          font-size: $font-size-base;
          color: $white;
          background-color: $red-light;
          padding: 0.625rem 1.5rem;
          .img-fluid {
            margin-right: 0.5rem;
          }
          &:hover {
            background-color: $red-dark;
          }
          &:after {
            display: none;
          }
        }
      }
    }
    .media-brand {
      margin-top: -0.3em;
      .nav-item {
        display: flex;
        align-items: center;
        .nav-link {
          padding-left:0;
          &:hover {
            img {
              display:none;
            }
            .icon-text {
              transition: all .3s ease-out;
              opacity: 1;
              display: flex;
            }
          }
          .icon-text {
            border: 1px solid $red-light;
            border-radius: 50%;
            font-size: 9px;
            width:21px;
            height: 21px;
            align-items: center; 
            justify-content: center;
            display: none;
            opacity:0;
            transition: all .2s ease-out;
        
          }
          &:after {
            display: none;
            width:0;
            height: 0;
          }
        }
      }
    }
    .ticket {
      @media (min-width: 1200px) {
        margin-left: 1rem;
      }
    }
  }
  &.nav-color,
  &.balado-active  {
    background-color: white;
    border-bottom: 1px solid $red-light;
    padding: 1em 2.5em 1em 2.5em;
    @media (max-width: 1200px) {
      padding: 1em 1.5em 1em 1.5em;
    }
    .navbar-brand {
      display: inline-flex;
      align-items: center;
      width:35%;
      @media (min-width: 1530px) and (max-width: 1630px) {
        width:27%
      }
      @media (min-width: 1375px) and (max-width: 1530px) {
        width:27%
      }
      @media (min-width: 1200px) and (max-width: 1375px) {
        width:24%
      }
      .logo-mem { 
        display: inline-block;
        width:17%;
        margin-right: 2em;
        @media (min-width: 1530px) and (max-width: 1630px) {
          margin-right: 1.5em;
          width:25%;
        }
        @media (min-width: 1200px) and (max-width: 1530px) {
          margin-right: 1em;
          width:25%;
        }
      }
      
      @include media-breakpoint-down(lg) {
        width:auto;
        .logo-mem { 
          width: 4em;
        }
        .signature-mobile {
          display:none;
        }
      }
    }
    .nav-link {
      @media (min-width: 1375px) and (max-width: 1500px) {
        font-size: 1.1rem!important;
      }
      @media (min-width: 1200px) and (max-width: 1375px) {
        font-size: 0.99rem!important;
      }
    }
    .navbar-lang-switcher {
      @media (min-width: 1200px) and (max-width: 1375px) {
        padding: 0!important;
      }
    }
  }

  .navbar-collapse {
    @include media-breakpoint-down(lg) {
        background: #fff;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-content: center;
        text-align: center !important;
        transition: all .25s ease-in-out;
        &.collapse {
            opacity: 0;
            pointer-events: none;
            display: flex;
        }
        &.collapsing[style*="height"] {
          opacity: 1;
          height: 100%!important;
        }
        &.collapsing {
          opacity: 0;
          height: 100%;
        }

        &.collapse.show {
          opacity: 1;
          pointer-events: auto;  
        }
        .navbar-nav {
          margin-right: initial !important;
          margin-top: auto;
          margin-bottom: auto;
        }
    }
  } 
}
//Menu open in mobile
.mobile-menu-open {
  overflow: hidden!important;
  transition: all .25s ease-in-out;
  .ticket {
    .nav-item {
      display: inline-block;
    }
  }
  .nav-link {
    font-size: $font-size-xmedium!important;//28
    margin-bottom: 0.5em;
    &.ticket-link {
      font-size: $font-size-base!important; //16
      margin-top: 2.5rem;
    }
  }

  .media-brand { 
    margin-top: 0!important;
    .nav-item {
      margin-right:1.5em;
      .nav-link {
        img {
          width:41px;
        }
        .icon-text {
          width:41px!important;
          height: 41px!important;
          font-size: 18px!important;
        }
        &::after {
          margin: 0;
          width:0;
          height: 0;
        }
      }
    } 
  }
  .navbar {
    .navbar-brand {
      .signature-mobile {
        display: block;
        z-index:2;
        position: relative;
      }
    }
    &.nav-color,
    &.balado-active {
      .navbar-brand {
        width:35%;
        @media (min-width: 1530px) and (max-width: 1630px) {
          width:28%
        }
        @media (min-width: 1375px) and (max-width: 1530px) {
          width:27%
        }
        @media (min-width: 1200px) and (max-width: 1375px) {
          width:25%
        }
        .signature-mobile {
          display: block;
          z-index:2;
        }
        .logo-mem {
          display:none;
        }
      }
    }
    .navbar-nav {
      @media (max-width:992px) {
        margin-top: 25%;
      }
      @media (max-width:768px) {
        margin-top: 35%;
      }
      // @media (max-width:567px) {
      //   margin-top: 45%;
      // }
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar-nav {
      margin-left: initial!important;
  }
}


/* animation hamburger menu */
.navbar-toggler {
  z-index: 10;
  border-color: transparent!important;
  outline: none;
  position: absolute;
  top: 1em;
  right: 1em;
  padding-top: 0!important;
  transition: all 0.2s;
  &:focus{
    outline: none;
    outline-color: transparent;
    outline-style:none;
  }
  &[aria-expanded="false"]{
    .icon-bar{
      width: 60px;
      height: 2px;
      border-radius: 2px;
      background: $red-light;
      display: block;
      transform: rotate(0deg) translate(0px, 0px);
      transition: all 300ms ease-in-out;
      &:last-child {
        margin-top: 14px;
      }
      @media (max-width: 567px) {
        width: 50px;
      }
    }
  }
  &[aria-expanded="true"]{
    // transform: translateY(-25%);
    .icon-bar{
      position: relative;
      background: $black;
      display: block;
      transition: all 300ms ease-in-out;
      width: 60px;
      height: 2px;
      // top: 1em;
      border-radius: 2px;
      background: $red-light;
      -o-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      transition: ease all .2s;
      margin-top: -2px;
      &:nth-child(2) {
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transition: ease all .2s;
      }
      @media (max-width: 567px) {
        width: 50px;
      }
    }
  }
  @media (max-width: 1200px) {
    right: 0;
  }
}





:lang(fr) {
  .navbar-lang-switcher {
    li:first-child {
      a {
        display: block;
      }
    }
    li:last-child {
      a {
        display: none;
      }
    }
  }
}

:lang(en) {
  .navbar-lang-switcher {
    li:first-child {
      a {
        display: none;
      }
    }
    li:last-child {
      a {
        display: block;
      }
    }
  }
}
